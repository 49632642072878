const location = ({ cities, sectors }) => ([
  {
    label: 'Villes',
    values: cities.map(({ slug, name }) => {
      return {
        label: name,
        value: slug
      }
    })
  },
  {
    label: 'Quartiers',
    values: sectors.map(({ slug, name }) => {
      return {
        label: name,
        value: slug
      }
    })
  }
])

export {
  location
}

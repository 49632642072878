import { Controller } from '@hotwired/stimulus'

// Adapted from https://github.com/stimulus-components/stimulus-remote-rails/blob/v3.0.1/src/index.ts

export default class extends Controller {
  static values = {
    targetSelector: String
  }

  replace (event) {
    this.target.innerHTML = event.detail.data
  }

  append (event) {
    this.target.insertAdjacentHTML("beforeend", event.detail.data)
  }

  prepend (event) {
    this.target.insertAdjacentHTML("afterbegin", event.detail.data)
  }

  pushHistory (event) {
    const formData = new FormData(this.element.closest("form"))
    // get the current URL without the query parameters
    const url = new URL(window.location.href.split('?')[0])

    for (const [name, value] of formData.entries()) {
      url.searchParams.append(name, value)
    }

    window.history.pushState({}, "", url)
  }

  get target () {
    if (this.hasTargetSelectorValue) {
      return document.querySelector(this.targetSelectorValue)
    } else {
      return this.element
    }
  }
}

<template lang="pug">
  .searchbar.searchbar--line.searchbar--line--sm
    .searchbar-close(@click.prevent="$emit('triggerclose')")
      i.fal.fa-times
    .searchbar__content
      .searchbar__destination.searchbar__multiselect.p-0
        .form-icon-wrapper.form-icon-wrapper--left
          destination-select(
            v-model="destinationOrDestinationType"
            :destinations="destinations"
            :translations="translations"
          )
          i.fal.fa-map

      div.searchbar__submit
        button(class="btn btn-primary btn-block py-4") {{translations.search}}
</template>

<script>
  import DestinationSelect from '@components/Search/DestinationSelect'

  export default {
    components: {
      DestinationSelect
    },

    props: {
      params: {
        type: Object,
        default: () => ({})
      },

      destinations: {
        type: Array,
        required: true
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        destinationOrDestinationType: this.params.by_destination_id || this.params.by_destination_type
      }
    }
  }
</script>

<template lang="pug">
  div
    .searchbar.searchbar--season
      .searchbar-close(@click.prevent="$emit('triggerclose')")
        i.fal.fa-times

      .row.pb-0
        .col-lg-12.mb-5.mb-lg-0(v-if="hasRentablePerSeasonFilter")
          .uppercase.searchbar__subtitle {{translations.stay_duration}}
          rentable-per-season-filter(
            :initial-value="params.by_rentable_per_season"
          )

      .pt-0.d-lg-flex
        .searchbar__multiselect.p-0.flex-grow
          .form-icon-wrapper.form-icon-wrapper--left
            accommodation-type-select(
              :accommodation-types="accommodationTypes"
              :initial-accommodation-type="params.accommodation_type"
              :translations="translations"
            )
            i.fal.fa-home

        .searchbar__multiselect.p-0.flex-grow
          .form-icon-wrapper.form-icon-wrapper--left
            location-select(
              by-rental-accommodations=true
              :destination-or-destination-type="destinationOrDestinationType"
              :initial-city-or-sector="params.city_or_sector"
              :initial-location-name="initialLocationName"
              :translations="translations"
            )
            i.fal.fa-map-marker-alt

        .mb-4.mb-lg-0.flex-grow
          life-styles-select(
            :initial-life-styles="params.by_life_styles"
            :life-styles="lifeStyles"
            :translations="translations"
          )

        .mb-4.mb-lg-0.flex-grow
          amenities-select(
            :initial-amenities="params.by_amenities"
            :translations="translations"
          )

        .mb-4.mb-lg-0.flex-grow
          people-select(
            :initial-value="params.by_people"
            :translations="translations"
            :minimum-value="minimumPeopleCapacity"
            :maximum-value="maximumPeopleCapacity"
          )

        .mb-4.mb-lg-0.flex-grow
          budget-select(
            :initial-min-budget="params.by_min_budget"
            :initial-max-budget="params.by_max_budget"
            :translations="translations"
          )

        .mb-4.mb-lg-0.flex-grow.searchbar__name
          .btn-outline-graylight.p-0.flex-grow
            .form-icon-wrapper.form-icon-wrapper--left
              reference-agency-input(
                name="by_title"
                placeholder="Nom"
                :initial-value="params.by_title"
              )
              i.fal.fa-bookmark

      button(type="submit" class="btn btn-primary btn-block") {{translations.search}}

    .searchbar__bottom(v-if="hasSpecialRentalAccommodations || hasSortFilter")
      //- featured-filter(
      //-   v-if="hasSpecialRentalAccommodations"
      //-   :initial-featured="params.by_featured"
      //-   :offer-options="offerOptions"
      //-   :translations="translations"
      //- )
      .result-sort(v-if="hasSortFilter")
        sort-filter(
          :initial-sort-option="params.sort"
          :sort-options="sortOptions"
          :translations="translations"
          class="multiselect-sort multiselect-sort--lg"
        )
</template>

<script>
  import RentablePerSeasonFilter from '@components/RentalAccommodation/RentablePerSeasonFilter'
  import LifeStylesSelect from '@components/RentalAccommodation/LifeStylesSelect'
  import AmenitiesSelect from '@components/RentalAccommodation/AmenitiesSelect'
  import PeopleSelect from '@components/RentalAccommodation/PeopleSelect'
  import BudgetSelect from '@components/RentalAccommodation/BudgetSelect'
  import FeaturedFilter from '@components/RentalAccommodation/FeaturedFilter'
  import ReferenceAgencyInput from '@components/Accommodation/ReferenceAgencyInput'
  import AccommodationTypeSelect from '@components/Search/AccommodationTypeSelect'
  import LocationSelect from '@components/LocationSelect'
  import { get } from 'lodash'

  export default {
    components: {
      RentablePerSeasonFilter,
      LifeStylesSelect,
      AmenitiesSelect,
      PeopleSelect,
      BudgetSelect,
      FeaturedFilter,
      ReferenceAgencyInput,
      LocationSelect,
      AccommodationTypeSelect

    },

    props: {
      params: {
        type: Object,
        default: () => ({})
      },

      hasSpecialRentalAccommodations: {
        type: Boolean,
        required: false
      },

      hasSortFilter: {
        type: Boolean,
        required: false
      },

      hasRentablePerSeasonFilter: {
        type: Boolean,
        default: false
      },

      translations: {
        type: Object,
        required: true
      },

      lang: {
        type: String,
        required: true
      },

      minimumPeopleCapacity: {
        type: Number,
        default: 1
      },

      maximumPeopleCapacity: {
        type: Number,
        default: 20
      },

      lifeStyles: {
        type: Array,
        required: true
      },

      accommodationTypes: {
        type: Array,
        required: true
      },

      initialLocationName: {
        type: String,
        default: null
      }
    },

    data () {
      return {
        destinationOrDestinationType: this.params.by_destination_id || this.params.by_destination_type // TODO: remove ?
      }
    },

    computed: {
      sortOptions () {
        return [
          {
            label: get(this.translations, 'sorts.price_asc'),
            value: 'sort_by_price_per_week asc'
          },
          {
            label: get(this.translations, 'sorts.price_desc'),
            value: 'sort_by_price_per_week desc'
          },
          {
            label: get(this.translations, 'sorts.bedrooms_asc'),
            value: 'sort_by_bedrooms asc'
          },
          {
            label: get(this.translations, 'sorts.bedrooms_desc'),
            value: 'sort_by_bedrooms desc'
          }
        ]
      },

      offerOptions () {
        return [
          {
            label: get(this.translations, 'offers.all'),
            value: ''
          },
          {
            label: get(this.translations, 'offers.featured'),
            value: 'featured'
          }
        ]
      }
    }
  }
</script>

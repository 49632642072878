<template lang="pug">
  div
    active-item
      .vue-dropdown.vue-dropdown--mobile(:class="{ active: active }" slot-scope="{ setActive, active }"  v-click-outside="() => setActive(false)")
        .vue-dropdown__btn.btn.btn-outline-graylight(@click.prevent="setActive(!active)")
          i.fal.fa-coin.mr-2
          span {{ label }}

        .vue-dropdown__menu(v-show="active")
          .row.sm-gutter
            .col-6.sm-gutter
              .form-icon-wrapper
                input.form-control.form-control--sm(v-model="by_min_budget" name="by_min_budget" placeholder="Min" v-money="money_for_budget(by_min_budget)")
                i.fal.fa-euro-sign
            .col-6.sm-gutter
              .form-icon-wrapper
                input.form-control.form-control--sm(v-model="by_max_budget" name="by_max_budget" placeholder="Max" v-money="money_for_budget(by_max_budget)")
                i.fal.fa-euro-sign
</template>

<script>
  import { VMoney } from 'v-money'

  export default {
    directives: {
      money: VMoney
    },

    props: {
      initialMinBudget: {
        type: String,
        default: null
      },

      initialMaxBudget: {
        type: String,
        default: null
      },

      budgetize: {
        type: String,
        default: null
      }
    },

    data () {
      return {
        money: {
          decimal: ',',
          thousands: ' ',
          prefix: '',
          suffix: '',
          precision: 0,
          masked: false
        },

        by_min_budget: this.initialMinBudget,
        by_max_budget: this.initialMaxBudget
      }
    },

    computed: {
      label () {
        if (this.budgetize) {
          return this.budgetize
        }

        return "Budget"
      }
    },

    methods: {
      money_for_budget (budget) {
        budget = budget || ''
        budget = budget.replace(/\s+/g, '')

        if (budget >= 1) {
          return this.money
        }

        return null
      }
    }
  }
</script>

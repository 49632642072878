/* global AccsShowPage */

import Vue from 'vue'
import axios from 'axios'
import ActiveItem from '@components/ActiveItem'
import SortFilter from '@components/Search/SortFilter'
import PrettyCheckbox from 'pretty-checkbox-vue'

// Directive
import vClickOutside from 'v-click-outside'

// Automatically register components in the "pages/application" folder.
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => {
  let component = key.split('/')
  component.shift()
  component = component.join('').split('.')[0]

  Vue.component(component, () => import(`${key}`))
})

// Components
Vue.component('ActiveItem', ActiveItem)
Vue.component('SortFilter', SortFilter)
Vue.component('Multiselect', () => import('vue-multiselect'))

// Plugins
Vue.use(PrettyCheckbox)
Vue.use(vClickOutside)

// Configuration
Vue.config.performance = true
Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue()

document.addEventListener('DOMContentLoaded', () => {
  const token = document.head.querySelector('meta[name="csrf-token"]').content
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common.Accept = 'application/json'
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  new Vue({
    el: '#vue-app',
    mounted () {
      if ("GoogleMap" in window && window.location.pathname !== "/agences" && window.location.pathname !== "/en/agencies") {
        new AccsShowPage()
      }
    }
  })
})

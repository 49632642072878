<template lang="pug">
  div
    .input-group-radio-button.text-center
      .input-btn-radio-button.input-btn-radio-button--alpha
        input(type="radio" id="sales" name="contract_type" value="sales" :checked="value === 'sales'" @input="$emit('input', $event.target.value)")
        label.text-center(for="sales") {{translations.buy}}

      .input-btn-radio-button.input-btn-radio-button--alpha
        input(type="radio" id="rents" name="contract_type" value="rents" @input="$emit('input', $event.target.value)")
        label.text-center(for="rents") {{translations.rent}}

      a.input-btn-radio-button(:href="rentalAccommodationsPath")
        label.text-center {{translations.vacation_rentals}}
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: null
      },

      rentalAccommodationsPath: {
        type: String,
        required: true
      },

      translations: {
        type: Object,
        required: true
      }
    }
  }
</script>

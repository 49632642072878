<template lang="pug">
  div
    form(:action="accommodationsPath" method="get")
      .searchbar.searchbar--line
        .searchbar-close(@click.prevent="$emit('triggerclose')")
          i.fal.fa-times

        contract-type-select(
          v-model="contractType",
          :translations="translations"
          :rental-accommodations-path="rentalAccommodationsPath"
        )

        accommodations-search(
          :destinations="destinations"
          :contract-type="contractType"
          :accommodation-types="accommodationTypes"
          :translations="translations"
        )
</template>

<script>
  import ContractTypeSelect from '@components/ContractTypeSelect'

  export default {
    components: {
      ContractTypeSelect
    },

    props: {
      accommodationsPath: {
        type: String,
        required: true
      },

      rentalAccommodationsPath: {
        type: String,
        required: true
      },

      params: {
        type: Object,
        required: true
      },

      destinations: {
        type: Array,
        required: true
      },

      accommodationTypes: {
        type: Array,
        required: true
      },

      translations: {
        type: Object,
        required: true
      },

      lang: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        contractType: 'sales'
      }
    }
  }
</script>

<template lang="pug">
  div
    input(type="hidden" v-for="amenity in selectedAmenities" name="by_amenities[]" :value="amenity")

    active-item
      .vue-dropdown.vue-dropdown--mobile(:class="{ active: active }" slot-scope="{ setActive, active }" v-click-outside="() => setActive(false)")
        .vue-dropdown__btn.btn.btn-outline-graylight(@click.prevent="setActive(!active)")
          span {{translations.amenities_label}}
            mark(v-if="selectedAmenities.length")
              | {{ selectedAmenities.length }}
        .vue-dropdown__menu(v-show="active" style="left: 0;max-height:300px;")
          .icon-rental-accommodation
            p-check(class="p-icon p-plain" toggle="" color="primary-o" :key="amenity" v-model="selectedAmenities" :value="amenity" v-for="amenity in Object.keys(amenitiesObj)")
              i.icon(slot='extra' :class="amenitiesObj[amenity]")
              | {{ translations.amenities[amenity] }}

              i.icon(slot='off-extra' :class="amenitiesObj[amenity]")
              label(slot='off-label') {{ translations.amenities[amenity] }}

          .text-right.mt-3
            a.btn.btn-link.text-primary.underline(
              href="#"
              @click.prevent="selectedAmenities = []"
            ) {{translations.reset}}
</template>

<script>
  export default {
    props: {
      initialAmenities: {
        type: Array,
        default: () => ([])
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        selectedAmenities: [],
        amenitiesObj: {
          hammam: "far fa-raindrops",
          swimming_pool: "far fa-swimming-pool",
          sauna: "far fa-heat",
          gym: "far fa-dumbbell"
        }
      }
    },

    mounted () {
      this.selectedAmenities = [...this.initialAmenities]
    }
  }
</script>

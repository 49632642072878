<template lang="pug">
  div
    active-item
      .vue-dropdown(:class="{ active: active }" slot-scope="{ setActive, active }"  v-click-outside="() => setActive(false)")
        .vue-dropdown__btn.btn.btn-outline-graylight(@click.prevent="setActive(!active)")
          i.fal.fa-user.mr-2
          span(v-if="value") {{ value }} {{ translations.people }}
          span(v-else) {{ translations.people_label }}

        .vue-dropdown__menu.vue-dropdown__menu--sm(v-show="active")
          input(type="hidden" name="by_people" :value="value")

          a.form-control.vue-dropdown__menu-link(
            v-for="count in ['', '4', '6', '8', '10', '12', '14', '16', '18']"
            href="#"
            :class="{ selected: value === count }"
            @click.prevent="() => { setActive(false); value = count }"
          )
            span(v-if="count") {{ count }} {{ translations.people }}
            span(v-else)
              i -- {{ translations.whatever }} --
</template>

<script>
  export default {
    props: {
      initialValue: {
        type: String,
        default: null
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        value: this.initialValue
      }
    }
  }
</script>

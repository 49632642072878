<template lang="pug">
.btn-wrapper.mt-lg-4
  .input-group-radio-button.text-center
    .input-btn-radio-button
      input(type="radio" id="by_rentable_per_season-false" name="by_rentable_per_season" value="" v-model="value")
      label.text-center.py-4(for="by_rentable_per_season-false") À la semaine
    .input-btn-radio-button
      input(type="radio" id="by_rentable_per_season-true" name="by_rentable_per_season" value="true" v-model="value")
      label.text-center.py-4(for="by_rentable_per_season-true") À la saison
</template>

<script>
export default {
  props: {
    initialValue: {
      type: String,
      default: ""
    }
  },

  data () {
    return {
      value: this.initialValue
    }
  }
}
</script>

<template lang="pug">
  div
    input(v-if="destinationOrDestinationType && !['lake', 'mountain'].includes(destinationOrDestinationType.value)" type="hidden" name="by_destination_id" :value="destinationOrDestinationType.value")
    input(v-else-if="destinationOrDestinationType" type="hidden" name="by_destination_type" :value="destinationOrDestinationType.destinationType")

    multiselect(
      ref="multiselect"
      v-model="destinationOrDestinationType"
      label="label"
      track-by="value"
      :options="groupDestinations ? groupedOptions : flatOptions"
      :group-values="groupDestinations ? 'destinations' : null",
      :group-label="groupDestinations ? 'destinationType' : null",
      :show-labels="false",
      placeholder="Destination",
      @input="handleInput"
    )
      template(v-if="option.fullLabel" slot="singleLabel", slot-scope="{ option }")
        span {{ option.fullLabel }}

</template>

<script>
  import Multiselect from 'vue-multiselect'
  import { get } from 'lodash'

  export default {
    components: {
      Multiselect
    },

    props: {
      destinations: {
        type: Array,
        required: true
      },

      groupDestinations: {
        type: Boolean,
        default: true
      },

      value: {
        type: [String, Number],
        default: null
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        destinationOrDestinationType: null
      }
    },

    computed: {
      groupedOptions () {
        const mountainDestinations = this.destinations.filter(destination => destination.destination_type === 'mountain').map(({ id, title }) => {
          return {
            label: title,
            value: id,
            destinationType: 'mountain'
          }
        })
        if (mountainDestinations.length) {
          mountainDestinations.unshift({
            label: this.translations.all_destinations,
            fullLabel: this.translations.all_mountain_destinations,
            value: 'mountain',
            destinationType: 'mountain'
          })
        }

        const lakeDestinations = this.destinations.filter(destination => destination.destination_type === 'lake').map(({ id, title }) => {
          return {
            label: title,
            value: id,
            destinationType: 'lake'
          }
        })
        if (lakeDestinations.length) {
          lakeDestinations.unshift({
            label: this.translations.all_destinations,
            fullLabel: this.translations.all_lake_destinations,
            value: 'lake',
            destinationType: 'lake'
          })
        }

        return [
          {
            destinationType: this.translations.mountain,
            destinations: mountainDestinations
          },
          {
            destinationType: this.translations.lake,
            destinations: lakeDestinations
          }
        ]
      },
      flatOptions () {
        return [
          { label: this.translations.all_destinations, value: '' },
          ...this.destinations.map(({ id, title }) => ({ label: title, value: id }))
        ]
      }
    },

    watch: {
      destinations () {
        this.destinationOrDestinationType = null
      }
    },

    mounted () {
      const selectedValue = this.value.toString()
      const allOptions = this.groupDestinations ? this.groupedOptions.map(group => group.destinations).flat() : this.flatOptions
      this.destinationOrDestinationType = allOptions.find(option => option.value.toString() === selectedValue)
    },

    methods: {
      handleInput () {
        this.$emit('input', get(this.destinationOrDestinationType, 'value'))
      }
    }
  }
</script>

<template lang="pug">
  div
    input(type="hidden" v-for="lifeStyle in selectedLifeStyles" name="by_life_styles[]" :value="lifeStyle")

    active-item
      .vue-dropdown.vue-dropdown--mobile(:class="{ active: active }" slot-scope="{ setActive, active }" v-click-outside="() => setActive(false)")
        .vue-dropdown__btn.btn.btn-outline-graylight(@click.prevent="setActive(!active)")
          span {{translations.life_style}}
            mark(v-if="selectedLifeStyles.length")
              | {{ selectedLifeStyles.length }}
        .vue-dropdown__menu(v-show="active" style="left: 0;right: auto;")
          div.d-flex.flex-column
            div.mt-3(v-for="lifeStyle in lifeStyles")
              p-check(:key="lifeStyle.id" v-model="selectedLifeStyles" :value="lifeStyle.id")
                | {{ lifeStyle.title }}

          .text-right.mt-3
            a.btn.btn-link.text-primary.underline(
              href="#"
              @click.prevent="selectedLifeStyles = []"
            ) {{translations.reset}}
</template>

<script>
  export default {
    props: {
      initialLifeStyles: {
        type: Array,
        default: () => ([])
      },

      lifeStyles: {
        type: Array,
        required: true
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        selectedLifeStyles: []
      }
    },

    mounted () {
      this.selectedLifeStyles = [...this.initialLifeStyles.map(el => parseInt(el))]
    }
  }
</script>

import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'

export default class extends Controller {
  static targets = [
    'input',
    'results',
    'value'
  ]

  static values = {
    path: String,
    searchParam: { type: String, default: "q" },
    contentType: { type: String, default: "text/x.autocomplete+html" },
    minLength: { type: Number, default: 0 },
    strictMode: { type: Boolean, default: false }
  }

  connect () {
    if (!this.pathValue) throw new Error(`${this.identifier} controller: path must be supplied`)

    // keep track of valueTarget's original data-attribute keys
    if (this.hasValueTarget) {
      this.valueTargetOriginalDataAttributes = Object.keys(this.valueTarget.dataset)
    }

    this.resultsTarget.hidden = true

    this.inputTarget.setAttribute('autocomplete', 'off')
    this.inputTarget.setAttribute('spellcheck', 'false')

    this.mouseInResults = false

    this.debouncedFetchResult = debounce(this.fetchResults.bind(this), 300)

    this.inputTarget.addEventListener('focus', this.openResults.bind(this))
    this.inputTarget.addEventListener('input', this.debouncedFetchResult)
    this.resultsTarget.addEventListener('mousedown', this.preventResultsClose.bind(this))
    this.inputTarget.addEventListener('blur', this.closeResults.bind(this))
  }

  disconnect () {
    this.inputTarget.removeEventListener('focus', this.openResults)
    this.inputTarget.removeEventListener('input', this.debouncedFetchResult)
    this.resultsTarget.removeEventListener('mousedown', this.preventResultsClose)
    this.inputTarget.removeEventListener('blur', this.closeResults)
  }

  preventResultsClose () {
    this.mouseInResults = true
    this.resultsTarget.addEventListener('mouseup', () => (this.mouseInResults = false), { once: true })
  }

  fetchResults () {
    if (!this.pathValue) return

    // Reset hidden field
    if (this.hasValueTarget) {
      this.valueTarget.value = ""
      this.valueTarget.dispatchEvent(new Event('change'))
      this.clearDataAttributes(this.valueTarget, this.valueTargetOriginalDataAttributes)
    }

    const query = this.inputTarget.value.trim()

    if (!query || query.length < this.minLengthValue) return

    const url = new URL(this.pathValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append(this.searchParamValue, query)
    url.search = params.toString()

    const headers = { 'X-Requested-With': 'XMLHttpRequest', Accept: this.contentTypeValue }

    this.element.dispatchEvent(new CustomEvent('loadstart'))

    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
        this.element.dispatchEvent(new CustomEvent('load'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
        this.openResults()
      })
      .catch(() => {
        this.resultsTarget.innerHTML = null
        this.element.dispatchEvent(new CustomEvent('error'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
      })
  }

  openResults () {
    if (!this.resultsTarget.hidden) return
    this.resultsTarget.hidden = false
    this.element.setAttribute('aria-expanded', 'true')
  }

  closeResults () {
    if (this.mouseInResults) return
    if (this.resultsTarget.hidden) return
    this.resultsTarget.hidden = true
    this.inputTarget.removeAttribute('aria-activedescendant')
    this.element.setAttribute('aria-expanded', 'false')

    // Reset input field if hidden field is empty (i.e no option selected)
    if (this.strictModeValue && this.hasValueTarget && !this.valueTarget.value && this.inputTarget.value.length > 0) {
      this.inputTarget.value = ""
      this.valueTarget.dispatchEvent(new Event('change'))
      this.dispatch("inputCleared", { detail: { input: this.inputTarget } })
    }
  }

  selectOption (event) {
    event.preventDefault()

    this.inputTarget.value = event.currentTarget.dataset.text || event.currentTarget.textContent
    this.valueTarget.value = event.currentTarget.dataset.value
    // Delete current data-attributes from valueTarget
    this.clearDataAttributes(this.valueTarget, this.valueTargetOriginalDataAttributes)
    // copy the selected option's data-attributes, except data-action, data-text and data-value
    this.copyDataAttributes(event.currentTarget, this.valueTarget, ['action', 'text', 'value'])
    this.valueTarget.dispatchEvent(new Event('change'))

    this.closeResults()

    this.dispatch("optionSelected", { detail: { option: event.currentTarget } })
  }

  clearDataAttributes (element, attributesToIgnore = []) {
    Object.entries(element.dataset).forEach(([key, _]) => {
      if (!attributesToIgnore.includes(key)) {
        delete this.valueTarget.dataset[key]
      }
    })
  }

  copyDataAttributes (fromElement, toElement, attributesToIgnore = []) {
    Object.entries(fromElement.dataset).forEach(([key, value]) => {
      if (!attributesToIgnore.includes(key)) {
       toElement.dataset[key] = value
      }
    })
  }
}

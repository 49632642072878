// MODULES =====================================================================
import "@appPages/vue"
import "@controllers"
import 'jquery-ujs'
import delegate from "@utils/jquery-event-delegator"
import flatpickr from "flatpickr"
import { French } from "flatpickr/dist/l10n/fr.js"

import "stylesheets/application.sass"

import executeRecaptcha from "@utils/grecaptcha"

window.executeRecaptcha = executeRecaptcha

// Delegate jQuery UJS events as DOM events
delegate("ajax:before", { parameters: ["event"] })
delegate("ajax:beforeSend", { parameters: ["event", "xhr", "settings"] })
delegate("ajax:send", { parameters: ["event", "xhr"] })
delegate("ajax:success", { parameters: ["event", "data", "status", "xhr"] })
delegate("ajax:error", { parameters: ["event", "xhr", "status", "error"] })
delegate("ajax:complete", { parameters: ["event", "xhr", "status"] })
delegate("ajax:aborted:required", { parameters: ["event", "elements"] })
delegate("ajax:aborted:file", { parameters: ["event", "elements"] })

// Flatpickr
document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll('[data-flatpickr]').forEach(el => {
    const options = el.dataset.flatpickr ? JSON.parse(el.dataset.flatpickr) : {}
    if (options.locale === "fr") options.locale = French

    flatpickr(el, {
      altInput: true,
      altFormat: "d / m / Y",
      ...options
    })
  })
})

<template lang="pug">
  div
    input(v-if="selected" type="hidden" name="by_furnished" :value="selected.value")

    multiselect(
      ref="multiselect"
      v-model="selected"
      :options="values"
      label="label"
      track-by="value"
      :show-labels="false"
      :placeholder="translations.rental_type"
    )
      template(slot="beforeList")
        .multiselect__element(@click="handleReset")
          span.multiselect__option {{translations.all_rental_types}}
</template>

<script>
  import Multiselect from 'vue-multiselect'

  export default {
    components: {
      Multiselect
    },

    props: {
      initial: {
        validator: value => ["true", "false"].includes(value),
        default: null
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        selected: this.initial,
        values: [
          {
            value: "true",
            label: this.translations.furnished
          },
          {
            value: "false",
            label: this.translations.unfurnished
          }
        ]
      }
    },

    mounted () {
      this.selected = this.values.find(value =>
        value.value === this.initial
      )
    },

    methods: {
      handleReset () {
        this.selected = null

        this.$refs.multiselect.$refs.search.blur()
      }
    }
  }
</script>

<template lang="pug">
  div
    .searchbar__content
      .searchbar__destination.searchbar__multiselect.p-0.bg-white
        .form-icon-wrapper.form-icon-wrapper--left
          destination-select(
            v-model="destinationOrDestinationType"
            :destinations="destinations"
            :translations="translations"
            :group-destinations="contractType !== 'rents'"
          )
          i.fal.fa-map

      .searchbar__multiselect.p-0
        .form-icon-wrapper.form-icon-wrapper--left
          location-select(
            :destination-or-destination-type="destinationOrDestinationType"
            :initial-city-or-sector="params.city_or_sector"
            :initial-location-name="initialLocationName"
            :translations="translations"
          )
          i.fal.fa-map-marker-alt

      .searchbar__multiselect.p-0(v-if="contractType !== 'rents'")
        .form-icon-wrapper.form-icon-wrapper--left
          accommodation-type-select(
            :accommodation-types="accommodationTypes"
            :initial-accommodation-type="params.accommodation_type"
            :translations="translations"
          )
          i.fal.fa-home

      .searchbar__multiselect.p-0(v-if="contractType === 'rents'")
        .form-icon-wrapper.form-icon-wrapper--left
          furnished-select(
            :initial="params.by_furnished"
            :translations="translations"
          )
          i.fal.fa-couch

      .searchbar__bedroom.mb-4.mb-lg-0(v-if="contractType === 'rents'")
        bedrooms-select(
          :initial-bedrooms="params.nb_bedrooms"
          :translations="translations"
        )

      .searchbar__budget.p-0
        budget-select(
          :initial-min-budget="params.by_min_budget"
          :initial-max-budget="params.by_max_budget"
          :budgetize="budgetize"
        )

      .searchbar__reference.p-0(v-if="contractType !== 'rents'")
        .form-icon-wrapper.form-icon-wrapper--left
          reference-agency-input(:initial-value="params.ref_agency")
          i.fal.fa-bookmark

      div.searchbar__submit
        button(class="btn btn-primary btn-block py-4") {{translations.search}}

    slot(name="footer" :triggerSubmit="triggerSubmit" :sortOptions="sortOptions")
</template>

<script>
  import LocationSelect from '@components/LocationSelect'
  import ReferenceAgencyInput from '@components/Accommodation/ReferenceAgencyInput'
  import AccommodationTypeSelect from '@components/Search/AccommodationTypeSelect'
  import FurnishedSelect from '@components/Search/FurnishedSelect'
  import BudgetSelect from '@components/Search/BudgetSelect'
  import DestinationSelect from '@components/Search/DestinationSelect'
  import BedroomsSelect from '@components/Search/BedroomsSelect'
  import { get } from 'lodash'

  export default {
    components: {
      DestinationSelect,
      LocationSelect,
      AccommodationTypeSelect,
      BudgetSelect,
      FurnishedSelect,
      ReferenceAgencyInput,
      BedroomsSelect
    },

    props: {
      params: {
        type: Object,
        default: () => ({})
      },

      destinations: {
        type: Array,
        required: true
      },

      accommodationTypes: {
        type: Array,
        required: true
      },

      contractType: {
        type: String,
        required: true
      },

      initialLocationName: {
        type: String,
        default: null
      },

      budgetize: {
        type: String,
        default: null
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        destinationOrDestinationType: this.params.by_destination_id || this.params.by_destination_type
      }
    },

    computed: {
      sortOptions () {
        return [
          {
            label: get(this.translations, 'sorts.exclusive_mandate'),
            value: 'sort_by_exclusive_mandate desc'
          },
          {
            label: get(this.translations, 'sorts.price_asc'),
            value: 'sort_by_price asc'
          },
          {
            label: get(this.translations, 'sorts.price_desc'),
            value: 'sort_by_price desc'
          },
          {
            label: get(this.translations, 'sorts.created_at'),
            value: 'sort_by_created_at desc'
          },
          {
            label: get(this.translations, 'sorts.program_id'),
            value: 'sort_by_program_id asc'
          }
        ]
      }
    },

    methods: {
      triggerSubmit () {
        document.querySelector("[data-ref='form']").submit()
      }
    }
  }
</script>

<template lang="pug">
div
  input(v-if="featured" type="hidden" name="by_featured" :value="featured.value")

  .multiselect-sort.multiselect-sort--offers
    multiselect(
      v-model="featured"
      label="label"
      track-by="value"
      :placeholder="translations.offers_placeholder"
      :options="offerOptions"
      :searchable="false"
      :allow-empty="false"
      :show-labels="false"
    )
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },

  props: {
    offerOptions: {
      type: Array,
      required: true
    },

    initialFeatured: {
      type: [String, Boolean],
      default: false
    },

    translations: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      featured: null
    }
  },

  mounted () {
    this.featured = this.offerOptions.find(option => option.value === this.initialFeatured)
  }
}
</script>

// ~ Dispatch jQuery events as regular DOM events ~

// Delegated events are given a new name in the format `jquery:<original event name>`.
// If you delegate `ajax:send` you will be able to listen for `jquery:ajax:send`
// on native event listeners such as Stimulus actions and `EventTarget.addEventListener`.

// Notes:
//   * The first parameter must be called "event".
//   * The parameters can be accessed as members on the `event.detail` object.

// Example:
//   delegate 'ajax:send', { parameters: ['event', 'xhr'] }
//   document.addEventListener 'jquery:ajax:send', (event) -> console.log(event.detail)

// Credits:
//   https://gist.github.com/kaspermeyer/7fe28bb7c55c2810e7b5f3d5e67c1a44

export default (eventName, { parameters }) => {
  $(document).on(eventName, (...args) => {
    const data = {}

    parameters.forEach((name, i) => { data[name] = args[i] })

    const delegatedEvent = new CustomEvent(`jquery:${eventName}`, {
      bubbles: true,
      cancelable: true,
      detail: data
    })

    data.event.target.dispatchEvent(delegatedEvent)
  })
}

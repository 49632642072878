<template lang="pug">
  div
    input(v-if="selectedAccommodationType" type="hidden" name="accommodation_type" :value="selectedAccommodationType.value")

    multiselect(
      ref="multiselect"
      v-model="selectedAccommodationType"
      :options="accommodationTypes"
      label="label"
      track-by="value"
      :show-labels="false"
      :placeholder="translations.property_type"
    )
      template(slot="beforeList")
        .multiselect__element(@click="handleReset")
          span.multiselect__option {{translations.all_property_types}}
</template>

<script>
  import Multiselect from 'vue-multiselect'

  export default {
    components: {
      Multiselect
    },

    props: {
      accommodationTypes: {
        type: Array,
        required: true
      },

      initialAccommodationType: {
        type: String,
        default: null
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        selectedAccommodationType: null
      }
    },

    mounted () {
      this.selectedAccommodationType = this.accommodationTypes.find(accommodationType =>
        accommodationType.value === this.initialAccommodationType
      )
    },

    methods: {
      handleReset () {
        this.selectedAccommodationType = null

        this.$refs.multiselect.$refs.search.blur()
      }
    }
  }
</script>

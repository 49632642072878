<template lang="pug">
  div
    input(type="hidden" v-for="value in selectedValues" name="nb_bedrooms[]" :value="value")

    active-item
      .vue-dropdown.vue-dropdown--mobile(:class="{ active: active }" slot-scope="{ setActive, active }"  v-click-outside="() => setActive(false)")
        .vue-dropdown__btn.btn.btn-outline-graylight(@click.prevent="setActive(!active)")
          i.fal.fa-bed.mr-2
          span {{translations.bedrooms}}
            mark(v-if="selectedValues.length")
              | {{ selectedValues.length }}
        .vue-dropdown__menu(v-show="active")
          .input-square__wrapper
            p-check.input-square(:key="value" color="default" v-model="selectedValues" :value="value" v-for="value in values")
              | {{ value }}
          .text-right.mt-3
            button.btn.btn-link.text-primary.underline(type="submit") {{translations.apply}}
</template>

<script>
  export default {
    props: {
      initialBedrooms: {
        type: Array,
        default: () => ([])
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        selectedValues: [],
        values: ["1", "2", "3", "4", "5+"]
      }
    },

    mounted () {
      this.selectedValues = [...this.initialBedrooms]
    }
  }
</script>

<template lang="pug">
  input.form-control.border-0(
    type="text"
    :name="name"
    :value="value"
    :placeholder="placeholder"
  )
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "ref_agency"
    },

    placeholder: {
      type: String,
      default: () => "Ref"
    },

    initialValue: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      value: this.initialValue
    }
  }
}
</script>

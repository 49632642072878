<template lang="pug">
  div
    .searchbar__content
      .searchbar__destination.searchbar__multiselect.p-0
        .form-icon-wrapper.form-icon-wrapper--left
          destination-select(
            v-model="destinationOrDestinationType"
            :destinations="destinations"
            :translations="translations"
          )
          i.fal.fa-map

      .searchbar__multiselect.p-0
        .form-icon-wrapper.form-icon-wrapper--left
          accommodation-type-select(
            :accommodation-types="accommodationTypes"
            :initial-accommodation-type="params.accommodation_type"
            :translations="translations"
          )
          i.fal.fa-home

      .searchbar__bedroom.mb-4.mb-lg-0
        bedrooms-select(
          :initial-bedrooms="params.nb_bedrooms"
          :translations="translations"
        )

      .searchbar__budget.p-0
        budget-select(
          :initial-min-budget="params.by_min_budget"
          :initial-max-budget="params.by_max_budget"
          :budgetize="budgetize"
          :translations="translations"
        )

      div.searchbar__submit
        button(class="btn btn-primary btn-block py-4") Rechercher

    .searchbar__bottom
      .result-sort
        sort-filter(
          :trigger-submit="triggerSubmit"
          :initial-sort-option="params.sort"
          :translations="translations"
          :sort-options="sortOptions"
        )
</template>

<script>
  import DestinationSelect from '@components/Search/DestinationSelect'
  import AccommodationTypeSelect from '@components/Search/AccommodationTypeSelect'
  import BedroomsSelect from '@components/Search/BedroomsSelect'
  import BudgetSelect from '@components/Search/BudgetSelect'
  import { get } from 'lodash'

  export default {
    components: {
      DestinationSelect,
      AccommodationTypeSelect,
      BedroomsSelect,
      BudgetSelect
    },

    props: {
      params: {
        type: Object,
        default: () => ({})
      },

      destinations: {
        type: Array,
        required: true
      },

      accommodationTypes: {
        type: Array,
        required: true
      },

      initialLocationName: {
        type: String,
        default: null
      },

      budgetize: {
        type: String,
        default: null
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        destinationOrDestinationType: this.params.by_destination_id || this.params.by_destination_type
      }
    },

    computed: {
      sortOptions () {
        return [
          {
            label: get(this.translations, 'sorts.created_at'),
            value: 'sort_by_created_at desc'
          },
          {
            label: get(this.translations, 'sorts.price_asc'),
            value: 'sort_by_min_price asc'
          },
          {
            label: get(this.translations, 'sorts.price_desc'),
            value: 'sort_by_min_price desc'
          }
        ]
      }
    },

    methods: {
      triggerSubmit () {
        document.querySelector("[data-ref='form'").submit()
      }
    }
  }
</script>

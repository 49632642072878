<template lang="pug">
  div
    input(v-if="selectedCity" type="hidden" name="city_or_sector" :value="selectedCity.value")

    multiselect(
      ref="multiselect"
      id="ajax"
      class="multiselect-default multiselect-group"
      v-model="selectedCity"
      :options="options"
      label="label"
      :placeholder="translations.city_sector"
      group-values="values"
      group-label="label"
      :group-select="false"
      select-label=""
      :preserve-search="true"
      :internal-search="false"
      :show-labels="false"
      @search-change="asyncFind"
      @input="handleInput"
    )

      span(slot="noResult") {{translations.no_results}}
      span(slot="noOptions") {{translations.type_to_search}}
      template(slot="beforeList")
        .multiselect__element(@click="handleReset")
          span.multiselect__option.multiselect__option--default {{translations.all_cities_sectors}}
</template>

<script>
  import axios from 'axios'
  import { debounce } from 'lodash'
  import { location } from '@decorators'

  export default {
    props: {
      destinationOrDestinationType: {
        type: [String, Number],
        default: null
      },

      initialCityOrSector: {
        type: String,
        default: null
      },

      initialLocationName: {
        type: String,
        default: null
      },

      translations: {
        type: Object,
        required: true
      },

      byRentalAccommodations: {
        type: Boolean,
        default: false
      },

      triggerSubmit: {
        type: Function,
        default: () => {}
      }
    },

    data () {
      return {
        selectedCity: null,
        options: []
      }
    },

    computed: {
      destinationId () {
        if (this.isDestinationType) return

        return this.destinationOrDestinationType
      },

      destinationType () {
        if (!this.isDestinationType) return

        return this.destinationOrDestinationType
      },

      isDestinationType () {
        return ['lake', 'mountain'].includes(this.destinationOrDestinationType)
      }
    },

    watch: {
      destinationOrDestinationType: {
        immediate: true,
        handler (destinationOrDestinationType) {
          this.selectedCity = null

          if (destinationOrDestinationType) {
            this.search(null, this)
          }
        }
      }
    },

    mounted () {
      if (this.initialCityOrSector && this.initialLocationName) {
        this.selectedCity = {
          label: this.initialLocationName,
          value: this.initialCityOrSector
        }
      }
    },

    methods: {
      handleReset () {
        this.selectedCity = null
        this.$refs.multiselect.$refs.search.blur()
        this.handleInput()
      },

      handleInput () {
        this.$nextTick(() => this.triggerSubmit())
      },

      asyncFind (query) {
        this.search(query, this)
      },

      search: debounce((query, vm) => {
        axios.get('/api/v1/locations', {
          params: {
            query,
            by_destination_id: vm.destinationId,
            by_destination_type: vm.destinationType,
            by_rental_accommodations: vm.byRentalAccommodations
          }
        })
          .then(({ data }) => {
            vm.options = location(data)
          })
      }, 350)
    }
  }
</script>

<template lang="pug">
  div
    input(v-if="sortOption" type="hidden" name="sort" :value="sortOption.value")

    multiselect(
      ref="multiselect"
      v-model="sortOption"
      :options="sortOptions"
      label="label"
      track-by="value"
      :searchable="false"
      :show-labels="false"
      :allow-empty="false"
      :placeholder="translations.sort"
      @input="handleInput"
    )
</template>

<script>
  export default {
    props: {
      triggerSubmit: {
        type: Function,
        default: () => ({})
      },

      initialSortOption: {
        type: String,
        default: null
      },

      sortOptions: {
        type: Array,
        required: true
      },

      translations: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        sortOption: null
      }
    },

    mounted () {
      this.sortOption = this.sortOptions.find(sortOption => sortOption.value === this.initialSortOption)
    },

    methods: {
      handleInput () {
        this.$nextTick(() => this.triggerSubmit())
      }
    }
  }
</script>

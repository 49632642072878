export default async function executeRecaptcha (event) {
  event.preventDefault()
  const form = event.target.closest('form')

  if (!form.reportValidity()) {
    return
  }

  const hiddenInput = form.querySelector("[id^='g-recaptcha-response-data-']")
  const actionName = hiddenInput.name.match(/g-recaptcha-response-data\[(.+)\]/)[1]
  const siteKey = hiddenInput.dataset.sitekey

  const response = await new Promise((resolve, reject) => {
    grecaptcha.ready(async function () {
      resolve(await grecaptcha.execute(`${siteKey}`, { action: `${actionName}` }))
    })
  })

  hiddenInput.value = response

  if (form.getAttribute('data-remote') === 'true') {
    $(form).trigger('submit.rails')
  } else {
    form.submit()
  }
}

<script>
  export default {
    props: {
      isActive: {
        type: [Boolean, Object, Number],
        default: false
      }
    },
    data () {
      return {
        active: this.isActive
      }
    },
    methods: {
      setActive (active) {
        this.active = active
      }
    },
    render () {
      return this.$scopedSlots.default({
        setActive: this.setActive,
        active: this.active
      })
    }
  }
</script>
